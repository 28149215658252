.calendar {
  display: flex;
  align-items: center;

  .calendar_to {
    color: $primary-purple;
    padding: 0 10px;
    font-size: 30px;
  }
}
