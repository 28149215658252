#transactionHistory {
  .tab_demo {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 943px) {
      justify-content: center;
      margin-top: 120px;
    }

    @media (max-width: 414px) {
      flex-direction: column;
    }

    @media (max-width: 280px) {
      margin-top: 150px;
    }

    li {
      &:not(.export_tool) {
        display: inline-block;
        text-align: center;
        color: $primary-purple;
        min-width: 120px;
        height: 46px;
        border-radius: 23px;
        line-height: 1.5;
        border: solid 1px $primary-purple;
        border-radius: $button-radius;
        cursor: pointer;
        padding: 12px 20px;

        @media (max-width: 414px) {
          min-width: none;
          width: 100%;
        }

        &:first-child {
          margin-right: 10px;
        }

        &.active {
          color: #ffffff;
          border: solid 1px transparent;
          background-color: $primary-purple;
        }

        &:hover {
          border: solid 1px transparent;
          color: $white;
        }
      }

      &.export_tool {
        display: flex;
        justify-self: end;
        margin-left: auto;
        @include rtl-sass-prop-dual(margin, 0 0 0 auto, margin, 0 auto 0 0);

        /deep/ {
          .el-input__inner {
            text-align: center;
          }

          .el-input--prefix .el-input__inner {
            @include rtl-sass-prop-sd(padding-left, 0);
          }

          @media (max-width: 550px) {
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 130px;
            }
            .el-input--prefix .el-input__inner {
              padding-left: 5px;
            }
            .el-input--suffix .el-input__inner {
              padding-right: 5px;
            }
          }

          @media (max-width: 414px) {
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 90px;
            }

            .el-input__inner {
              font-size: 13px;
            }
          }

          @media (max-width: 280px) {
            flex-direction: column;
          }
        }

        @media (max-width: 943px) {
          position: absolute;
          top: -70px;
        }

        @media (max-width: 280px) {
          top: -100px;
        }

        .purple_button {
          @media (max-width: 414px) {
            height: 35px;
            margin-right: 0px;
            line-height: 12px;
          }
          @media (max-width: 280px) {
            margin-top: 15px;
          }
        }
      }
    }
  }

  .table_demo {
    .table_item {
      display: none;

      &.active {
        display: block;
      }
    }

    .status_box {
      .title {
        padding: 0px 0 40px;
        color: $primary-purple;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.42;
        color: $primary-purple;
      }

      li {
        padding-bottom: 32px;

        &:last-child {
          padding-bottom: 48px;
          margin-bottom: 0;
        }
      }

      strong {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.44;
        color: $primary-green;
        display: inline-block;
      }

      p {
        font-size: 16px;
        font-weight: 200;
        line-height: 1.56;
        color: $primary-purple;

        a {
          color: $primary-purple;
          text-decoration: underline;
        }
      }
    }
  }

  .dialog_btn_box {
    display: flex;
    justify-content: center;

    .el-button {
      margin: 0 5px;
      min-width: 140px;
    }
  }

  .cancelWithdrawDialog {
    text-align: center;

    h5 {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 550px) {
  #transactionHistory .table_demo .status_box {
    padding: 0 24px;
    .title {
      font-size: 20px;
      padding: 24px 0 34px;
    }

    strong {
      font-size: 16px;
      margin-bottom: 13px;
    }
  }
}
